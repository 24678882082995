import { PICKUP_MODIFY } from '../actions/types';


const initValue = {
  shipId: 0,
  confirmNumber: ''
};

const pickupModify = (state = initValue, action) => {
  switch(action.type) {
    case PICKUP_MODIFY:
      return action.payload;
    default:
      return state;
  }
};


export default pickupModify;
